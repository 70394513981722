import { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  fetchLgaList,
  fetchStatesList,
  getUserInformation,
} from "../../api/authApi";
import { TransactionType, handleKycAccess } from "./helper";
import { useLocation, useHistory } from "react-router-dom";
import { getPremiumSubscription } from "../../api/subscriptionApi";
import {
  accountCheck,
  PremiumFeatureRecord,
  UserRole,
  UserType,
} from "./constants";
import {
  getLoanDuration,
  getLoanReasonAndPattern,
  getLoanTerms,
} from "../../api/loanApi";
import { Form, notification } from "antd";
import { getTicket } from "../../api/ticketApi";
import { userInitalLoginAccount } from "./model";
import { validateAccount } from "../../api/transactionApi";

const initalState = {
  loading: false,
  isLoading: false,
};

/**
 *
 * @param {Form instance} form an antd form instance
 * @param {Array of Strings} optionalFields fields that are not required if any
 * @returns
 */

export const useFormValidation = (form, optionalFields) => {
  const [fields, setFields] = useState(form.getFieldsValue());
  const [valid, setValid] = useState(false);
  let optionals = optionalFields || [];

  const validate = useCallback(() => {
    setFields(form.getFieldsValue());
    let formFieldsValues = Object.values(
      form.getFieldsValue(
        Object.keys(form.getFieldsValue()).filter(
          (field) => !optionals.includes(field)
        )
      )
    );

    if (formFieldsValues.includes(undefined) || formFieldsValues.includes(""))
      return setValid(false);
    setValid(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  useEffect(() => {
    validate();
  }, [validate]);

  return { fields, valid, validate };
};

export const useGetUser = () => {
  const { user, authenticated } = useSelector((s) => s.auth);
  const role = user?.role === UserRole.ADMIN;
  const isEnabled =
    user?.isCommercialBankEnabled || user?.isPtspCommercialBankEnabled;
  const shouldAllowIntlPayment = user?.shouldAllowIntlPayment;

  const UserSet = new Set([UserType.BANKER, UserType.SALES_AGGREGATOR]);
  const isAuthorized = UserSet.has(user.businessType);
  const salesAccess = user?.privileges?.SALES || [];

  const canSeeCustomerPayout = isEnabled || salesAccess?.includes("CREATE");

  return {
    role,
    user,
    isEnabled,
    isAuth: authenticated,
    shouldAllowIntlPayment,
    isAuthorized,
    canSeeCustomerPayout,
  };
};

export const useDisclose = () => {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onToggle = () => {
    setIsOpen((prev) => !prev);
  };

  return {
    isOpen,
    onOpen,
    onClose,
    onToggle,
  };
};

export const useGetLocation = () => {
  const [location, setLocation] = useState({
    state: [],
    lga: [],
  });
  const [loader, setLoader] = useState(initalState);
  const [value, setValue] = useState(null);
  const mounted = useRef(null);

  useEffect(() => {
    mounted.current = true;
    async function fetchData() {
      setLoader((prev) => ({
        ...prev,
        [value ? "isLoading" : "loading"]: true,
      }));
      try {
        let response;
        if (value) {
          response = await fetchLgaList(value);
        } else {
          response = await fetchStatesList();
        }

        /** JSDoc tag to specify the type of the options variable.
         * @type {{ label: "string", value: "string", className: "string" }[]}
         */
        const options = response.data.map((item) => ({
          label: item,
          value: item,
          className: "rounded-md my-0.5",
        }));

        setLocation((prev) => ({
          ...prev,
          [value ? "lga" : "state"]: options.concat({
            label: "",
            value: "",
            disabled: true,
          }),
        }));
      } catch (error) {
        console.log(error);
      }
      setLoader(initalState);
    }
    fetchData();
    return () => (mounted.current = false);
  }, [value]);

  return {
    ...loader,
    ...location,
    setValue,
  };
};

export const useGetKycAccess = () => {
  const user = useSelector((s) => s.auth.user);
  const { role = null, department, disableKycCheck = false } = user;
  const canSeeKyc = useMemo(
    () => handleKycAccess(role, department, disableKycCheck),
    [role, department, disableKycCheck]
  );

  return canSeeKyc;
};

export const useGetAccess = () => {
  const user = useSelector((s) => s.auth.user);
  const { role = null, privileges = null, canCreateAggregator = false } = user;
  const isUserAdmin = role === UserRole.ADMIN;
  const salesAccess = privileges?.SALES || [];
  const disburseAccess = privileges?.DISBURSEMENT || [];
  const supportAccess = privileges?.SUPPORT || [];
  const disabled = UserRole.ADMIN !== role && !salesAccess.includes("CREATE");
  const canTransfer =
    UserRole.ADMIN === role || disburseAccess.includes("CREATE");
  const hasTicketAccess =
    UserRole.ADMIN === role ||
    salesAccess.includes("CREATE") ||
    supportAccess.includes("WRITE");
  const canSeeBusiness =
    UserRole.ADMIN === role ||
    canCreateAggregator ||
    salesAccess?.includes("READ");

  return {
    disabled,
    canTransfer,
    user,
    hasTicketAccess,
    canSeeBusiness,
    isUserAdmin,
  };
};

export const useUrlQuery = (key) => {
  const { search, state, pathname } = useLocation();
  const query = key ? new URLSearchParams(search).get(key) : search;
  return { query, state, pathname };
};

export const usePremiumType = ({ type, tab, text, target }) => {
  const renewalMessage = {
    show: true,
    title: `${text} Subscription Expired`,
    text: "Kindly renew to uncover valuable insights and make informed choices.",
    buttonText: "Renew Plan",
  };

  const freeTrialMessage = {
    show: false,
    title: `Try ${text} FREE for a Month!`,
    text: `Uncover valuable insights and make informed choices with ${text} 30-day trail.`,
    buttonText: "Start Your Free Trial",
  };

  const [modal, setModal] = useState(freeTrialMessage);
  const { premium = PremiumFeatureRecord } = useSelector(
    (state) => state.subscription
  );
  const premiumType = premium[type];
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const isActivated =
    premiumType?.subscriptionStatus?.toUpperCase() === "ACTIVATED";
  const isEmpty = premiumType?.subscriptionStatus === null;

  function navigate() {
    history.push({
      pathname: "/subscription/feature-plan",
      search: `?type=${tab}`,
      state: { [target]: isEmpty },
    });
  }

  function close() {
    history.push("/home");
  }

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const { data } = await getPremiumSubscription({ type });
        if (data.length === 0) {
          setModal((prev) => ({ ...prev, show: true }));
        } else if (data[0]?.subscriptionStatus?.toUpperCase() === "ACTIVATED") {
          setModal((prev) => ({
            ...prev,
            show: false,
          }));
        } else {
          setModal(renewalMessage);
        }
      } catch (err) {
        console.log(err);
      }
      setIsLoading(false);
    }
    fetchData();
    // eslint-disable-next-line
  }, [type]);

  return {
    isLoading,
    isActivated,
    modal,
    history,
    premium,
    close,
    navigate,
  };
};

export const useLoanMiscellaneous = () => {
  const [loading, setLoading] = useState(false);
  const [miscellaneous, setMiscellaneous] = useState({
    frequency: [],
    duration: [],
    pattern: [],
    terms: null,
  });

  const fetchLoanMiscellaneous = useCallback(async () => {
    try {
      setLoading(true);
      const [durationResponse, patternResponse, termsResponse] =
        await Promise.all([
          getLoanDuration(),
          getLoanReasonAndPattern(),
          getLoanTerms(),
        ]);
      setMiscellaneous({
        duration: durationResponse.data,
        frequency: patternResponse.data.frequency,
        pattern: patternResponse.data.pattern,
        terms: termsResponse.data.terms,
      });
    } catch (error) {
      notification.error({ message: "Error!", description: error.message });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchLoanMiscellaneous();
  }, [fetchLoanMiscellaneous]);

  return { miscellaneous, loading };
};

export const useTicketOption = () => {
  const { ticketCategories } = useSelector((state) => state.support);
  const options = {
    search: [
      {
        label: "Ticket ID",
        value: "ticketId",
      },
    ],
    filters: [
      {
        fieldName: "status",
        label: "Ticket Status",
        options: [
          { name: "Opened", value: "opened" },
          { name: "Pending", value: "pending" },
          { name: "Closed", value: "closed" },
          { name: "Unassigned", value: "unassigned" },
        ],
      },
      {
        fieldName: "category",
        label: "Ticket Type",
        options: ticketCategories?.map((type) => {
          const modifiedName = type.category.includes("Cable TV")
            ? "Cable TV"
            : type.category.includes("Electricity")
            ? TransactionType.PHCN
            : type.category;

          return {
            name: modifiedName,
            value: type.category,
            className: "w-full",
          };
        }),
      },
    ],
  };
  return options;
};

export const useGetTicketInfoById = ({
  id,
  userId,
  isCreatedByBanker,
  disableErr,
}) => {
  const mounted = useRef(false);
  const [loading, setLoading] = useState(false);
  const [ticketInfo, setTicketInfo] = useState({
    ticket: {},
    transactionDetails: {},
    gatewayResponse: {},
    customer: {},
    imageList: [],
  });

  const fetchTicket = useCallback(async () => {
    if (id) {
      setLoading(true);
      try {
        const result = await getTicket(id, userId, isCreatedByBanker);
        const dataResponse = result.data;

        if (mounted.current) {
          const { "Gateway Response": gatewayResponse, ...rest } =
            dataResponse.transactionDetails;
          setTicketInfo({
            ticket: dataResponse.ticket,
            gatewayResponse: gatewayResponse || {},
            transactionDetails: rest,
            customer: dataResponse.customerInfo,
            imageList: dataResponse.imageList || [],
          });
        }
      } catch (error) {
        if (!disableErr) {
          notification.error({ message: "Error!", description: error.message });
        }
        console.log(error);
      }
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, userId, isCreatedByBanker]);

  useEffect(() => {
    mounted.current = true;
    fetchTicket();
    return () => {
      mounted.current = false;
    };
  }, [fetchTicket]);

  return { ticketInfo, loading };
};

export const useFetchUserInfo = () => {
  const mounted = useRef(false);
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState(userInitalLoginAccount);

  useEffect(() => {
    mounted.current = true;
    const fetchData = async () => {
      setLoading(true);
      try {
        const userDataResponse = await getUserInformation();
        if (mounted.current) {
          setUserInfo(userDataResponse.data);
        }
      } catch (error) {
        notification.error({
          message: "Error!",
          description: error.message,
        });
      }
      setLoading(false);
    };

    fetchData();

    return () => {
      mounted.current = false;
    };
  }, []);

  return { userInfo, loading };
};

export const useAccountInfoValidation = ({ fieldName, codeName }) => {
  const { bankList } = useSelector((s) => s.transaction);
  const [{ status, message, bankName }, setStatus] = useState(accountCheck);
  const [form] = Form.useForm();
  const accountNumber = Form.useWatch(fieldName, form);
  const bankCode = Form.useWatch(codeName, form);

  function handleSelection(_, { label }) {
    setStatus((prev) => ({
      ...prev,
      bankName: label.props.children[1],
    }));
  }

  useEffect(() => {
    async function fetchAccount() {
      if (bankCode && accountNumber?.length >= 10) {
        setStatus((prev) => ({
          ...prev,
          message: "",
          status: "validating",
        }));
        try {
          const checkResponse = await validateAccount({
            bankCode,
            accountNumber,
          });
          form.setFieldsValue({ accountName: checkResponse.data.accountName });
          setStatus((prev) => ({
            ...prev,
            bankName: checkResponse.data.bankName,
            status: "success",
          }));
        } catch (error) {
          setStatus((prev) => ({
            ...prev,
            message: error.message,
            status: "error",
          }));
        }
      }
    }
    fetchAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountNumber, bankCode]);

  return {
    handleSelection,
    form,
    bankList,
    status,
    message,
    bankName,
  };
};
